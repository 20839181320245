import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'J.Cole',
    useCase: 'CREATIVE DIRECTION',
    description: `J.Cole partnered up with Missy Elliott for the video and song Nobody’s perfect.
    I Creative Directed and designed J.Coles and Missy Elliott’s wardrobe for this music video.`,
  },
  caseId: 'j-cole',
  contents: [
    {
      jwplayer: {
        videoSrc: 'https://content.jwplatform.com/videos/mmM29hVz-59UeS3WH.mp4',
        imageSrc: 'https://content.jwplatform.com/thumbs/mmM29hVz-720.jpg',
      },
    },
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
